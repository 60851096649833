import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import Header from "../components/Header";

import "../styles/index.scss";

const index = () => {
  const data = useStaticQuery(graphql`
    query ThumbnailPhotos {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "posts/thumbnails" }
        }
      ) {
        edges {
          node {
            extension
            dir
            modifiedTime
            id
            name
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const reversedata = [...data.allFile.edges].reverse();

  return (
    <main className="home-page">
      <title>{`:)`}</title>
      <Header />

      <ResponsiveMasonry
        columnsCountBreakPoints={{ 600: 1, 1200: 2, 1800: 3, 2400: 4 }}
      >
        <Masonry gutter={"16px"}>
          {reversedata.map((edge) => {
            const pageText = edge.node.name.split("-").join(" ");
            return (
              <Link
                to={`/${edge.node.name}`}
                key={edge.node.id}
                className="thumb-container"
              >
                <Img
                  className="object"
                  fluid={edge.node.childImageSharp.fluid}
                />
                <div className="link-text-container">
                  <div className="link-text">{pageText}</div>
                </div>
              </Link>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </main>
  );
};

export default index;
